import { DirectiveOptions } from 'vue/types/umd';
import { DirectiveBinding } from 'vue/types/options';

import { toTitleCase } from '@/core/helpers/utils/StringUtils';
import { VNode } from 'vue';

interface TitleCaseDirective extends DirectiveOptions {
  mirror: HTMLDivElement | null;
}

const directive: TitleCaseDirective = {
  mirror: null,

  bind(el: HTMLElement, binding: DirectiveBinding) {
    const element = el as HTMLFormElement;

    if (!!binding.value) {
      element.value = toTitleCase(binding.value);
    }
  },

  update(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    if (!!binding.value) {
      const expression = binding.expression!.split('.');
      const expressionLength = expression.length;

      if (vnode.context) {
        if (expressionLength === 1) {
          vnode.context.$set(vnode.context, expression[0], toTitleCase(binding.value));
        } else if (expressionLength === 2) {
          vnode.context.$set(
            (vnode.context as any)[expression[0]],
            expression[1],
            toTitleCase(binding.value),
          );
        } else if (expressionLength === 3) {
          vnode.context.$set(
            (vnode.context as any)[expression[0]][expression[1]],
            expression[2],
            toTitleCase(binding.value),
          );
        }
      }
    }
  },
};

export default directive;
